/** **************************************
* Fonts: app
*************************************** */
@import '@fontsource/public-sans/400.css';
@import '@fontsource/public-sans/500.css';
@import '@fontsource/public-sans/600.css';
@import '@fontsource/public-sans/700.css';
@import '@fontsource/public-sans/800.css';

@import '@fontsource/barlow/400.css';
@import '@fontsource/barlow/500.css';
@import '@fontsource/barlow/600.css';
@import '@fontsource/barlow/700.css';
@import '@fontsource/barlow/800.css';

/** **************************************
* Fonts: options
*************************************** */
@import '@fontsource/dm-sans/400.css';
@import '@fontsource/dm-sans/500.css';
@import '@fontsource/dm-sans/600.css';
@import '@fontsource/dm-sans/700.css';
@import '@fontsource/dm-sans/800.css';

@import '@fontsource/inter/400.css';
@import '@fontsource/inter/500.css';
@import '@fontsource/inter/600.css';
@import '@fontsource/inter/700.css';
@import '@fontsource/inter/800.css';

@import '@fontsource/nunito-sans/400.css';
@import '@fontsource/nunito-sans/500.css';
@import '@fontsource/nunito-sans/600.css';
@import '@fontsource/nunito-sans/700.css';
@import '@fontsource/nunito-sans/800.css';

/** **************************************
* Plugins
*************************************** */
/* scrollbar */
@import './components/scrollbar/styles.css';

/* image */
@import './components/image/styles.css';

/* map */
/* @import './components/map/styles.css'; */

/* lightbox */
@import './components/lightbox/styles.css';

/* chart */
@import './components/chart/styles.css';

/** **************************************
* Baseline
*************************************** */
html {
  height: 100%;
  -webkit-overflow-scrolling: touch;
}
body,
#root,
#root__layout {
  display: flex;
  flex: 1 1 auto;
  min-height: 100%;
  flex-direction: column;
}
img {
  max-width: 100%;
  vertical-align: middle;
}
ul {
  margin: 0;
  padding: 0;
  list-style-type: none;
}
input[type='number'] {
  -moz-appearance: textfield;
  appearance: none;
}
input[type='number']::-webkit-outer-spin-button {
  margin: 0;
  -webkit-appearance: none;
}
input[type='number']::-webkit-inner-spin-button {
  margin: 0;
  -webkit-appearance: none;
}
